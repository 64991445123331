import React, { useState, useEffect } from "react"

export default function SubscribeDark() {
  if (typeof window !== `undefined`) {
    ;(function (widgets) {
      var url = "https://widget.datablocks.se/api/rose"
      if (!window._MF) {
        var b = document.createElement("script")
        b.type = "text/javascript"
        b.async = true
        b.src = url + "/assets/js/loader-v4.js"
        document.getElementsByTagName("body")[0].appendChild(b)
      }
      window._MF = window._MF || {
        data: [],
        url: url,
        ready: !!0,
        render: function () {
          window._MF.ready = !0
        },
        push: function (w) {
          window._MF.data.push(w)
        },
      }
      window._MF.push(widgets)
    })([
      {
        query: "#subscribe",
        widget: "subscribe-v2",
        locale: "en",
        token: "b3772332-154d-48dd-8e70-78a8bdbe1e30",
      },
    ])
  }

  return (
    <div>
      <div className="mfn-widget" id="stock-table">
        <div className="max-w-3xl text-base  mx-auto">
          <h3 className="text-center text-white font-bold py-8 text-4xl">
            Subscribe
          </h3>
          <div id="subscribe" className="dark" />
        </div>
      </div>
    </div>
  )
}
