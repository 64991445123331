import React from "react"
import Footer from "../../components/Footer"
import Layout from "../../components/layout"
import PageHeader from "../../components/PageHeader"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../../components/seo"
import IconQuotes from "../../components/IconQuotes"
import SubscribeDark from "../../components/investors/SubscribeDark"
export default function InvestorIndexPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 250 }) {
        id
        content
        title
      }
    }
  `)
  const pageTitle = "Investor Relations"
  const { content } = data?.wpPage
  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className="investor-container w-full my-8 md:py-16 ">
        <div className="max-w-3xl mx-auto">
          <div className="">
            <PageHeader text="Investor Relations" />
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="space-y-4"
            />
          </div>
        </div>
        <div className="bg-primary pb-8 my-8 -mx-4 px-4">
          <SubscribeDark />
        </div>
        <IconQuotes />
      </div>
      <Footer />
    </Layout>
  )
}
